import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import Container from '@components/Container/Container';
import { Formik, Form } from 'formik';
import Select from '@components/Select/Select';
import AsyncSelect from '@components/Select/AsyncSelect';
import Datepicker from '@components/Datepicker/Datepicker';
import { ReactDatePickerProps } from 'react-datepicker';
import Button from '@components/Button/Button';
import { dateMask } from '@masks';
import { REQUEST_TYPES_OPTION, MIME_TYPES } from '@constants';
import { download } from '@utils';
import { formatLocaleDate, DATE_FNS_ONLY_DATE_FORMAT } from '@date-time';
import { HttpClient } from '@api/HttpClient';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import { IDictionary } from '@models/base.model';
import { FormForReportSchema } from './ProposalsListReport.validation';

import './ProposalsListReport.scss';

interface IValues {
  request_type: IDictionary | null;
  company: IDictionary | null;
  range: Array<Date | null>;
}

const initialValues = {
  request_type: null,
  company: null,
  range: [null, null],
};

export const ProposalsListReport: React.FC = () => {
  const [endDate, setEndDate] = useState<Date>();

  const promiseOptions = async (inputValue: string): Promise<IDictionary[]> => {
    const resp = await HttpClient.post<Record<string, unknown>, ISimilarCompany[]>(
      'customer/reports/participant/list/',
      { search: inputValue }
    );
    return resp.map((el) => ({ label: el.name, value: el.id }));
  };

  const startFormingReport = async (
    requestType: string,
    companyId: string,
    range: string[]
  ): Promise<{ report_id: string }> => {
    return await HttpClient.post(
      `customer/${requestType.toLowerCase()}/proposals/${companyId}/report_by_period/`,
      { start: range[0], end: range[1] }
    );
  };
  const downloadReport = async (
    reportId: string,
    companyName: string,
    requestType: string
  ): Promise<void> => {
    let response;
    let shouldRetry;
    do {
      shouldRetry = false;
      response = await HttpClient.post<AxiosResponse>(
          `customer/proposals/reports/${reportId}/download/`,
          {},
          {
            headers: {
              Accept: MIME_TYPES.Excel,
            },
            responseType: 'blob',
          }
      );
      if (response.status === 202) {
        shouldRetry = true;
        await new Promise(resolve => setTimeout(resolve, 500)); // задержка перед повторным запросом
      }
    } while (shouldRetry)

    const blob = new Blob([response.data as BlobPart], {
      type: MIME_TYPES.Excel,
    });

    const fileName = `Список_предложений_${companyName}_${requestType}.xlsx`;

    download(blob, fileName);
  };

  return (
    <Container direction="vertical" className="proposals-list-report">
      <h3>Список предложений по участнику</h3>
      <p>Для формирования отчёта необходимо выбрать трек и указать участника.</p>
      <Formik<IValues>
        enableReinitialize={false}
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={FormForReportSchema}
        onSubmit={async (values: IValues) => {
          if (values.range[0] && !values.range[1]) {
            setEndDate(values.range[0]);
          }

          const data = await startFormingReport(
            values.request_type?.value as string,
            values.company?.value as string,
            [
              formatLocaleDate(values.range[0], DATE_FNS_ONLY_DATE_FORMAT),
              formatLocaleDate(values.range[1], DATE_FNS_ONLY_DATE_FORMAT),
            ]
          );
          await downloadReport(
            data.report_id,
            values.company?.label as string,
            values.request_type?.label as string
          );
        }}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form className="proposals-list-report__form">
              <Select
                name="request_type"
                value={values.request_type}
                wrapperClassName="proposals-list-report__field"
                options={REQUEST_TYPES_OPTION}
                isClearable
                placeholder="Выберите трек"
                label="Трек*"
                className=""
                onChange={async (v) => await setFieldValue('request_type', v)}
                size="large"
                error={touched.request_type ? errors.request_type : ''}
              />
              <AsyncSelect
                name="company"
                wrapperClassName="proposals-list-report__field"
                isClearable
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                value={values.company}
                placeholder="Выберите участника"
                label="Участник*"
                onChange={async (v) => await setFieldValue('company', v)}
                size="large"
                error={touched.company ? errors.company : ''}
              />
              <Datepicker
                size="large"
                name="range"
                customWrapperClassName="proposals-list-report__field"
                placeholderText="Выберите интервал дат"
                label="Дата начала и окончания отчётного периода*"
                selectsRange={true as unknown as ReactDatePickerProps['selectsRange']}
                startDate={values.range[0]}
                endDate={values.range[1] || endDate}
                onChange={(range) => {
                  setFieldValue('range', range);
                }}
                error={touched.range ? (errors.range as string) : ''}
                isClearable={true}
                mask={[...dateMask, ' ', '-', ' ', ...dateMask]}
              />
              <div>* Обязательные для заполнения поля</div>
              <Button
                size="large"
                htmlType="submit"
                type="primary"
                fullWidth={false}
                className="proposals-list-report__submit"
              >
                Сгенерировать отчет
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
